//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchTurnoverBillDetail, fetchTurnoverBillOrderList, fetchTurnoverBillRefundList } from "@/api/finance";
import Pagination from "@/components/Pagination";
import "@/utils/global.js";
import { transChargeType } from "@/utils/util";
import { getToken } from "@/utils/cache";
import { downloadFun } from "@/utils/util";
export default {
  name: "rechargeList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      bill_id: 0,
      orderLabel: '订单明细(0)',
      refundLabel: '退款明细(0)',
      orderSearchForm: {
        page: 1,
        per_page: 20,
        id: ''
      },
      refundSearchForm: {
        page: 1,
        per_page: 20,
        id: ''
      },
      tabName: 'order',
      // 表格数据
      orderData: {
        list: [],
        totalPage: 0,
        loading: false
      },
      refundData: {
        list: [],
        totalPage: 0,
        loading: false
      },
      bill: {
        agent_name: '',
        start_at: '',
        end_at: '',
        settle_money: 0,
        order_count: 0,
        order_money: 0,
        pay_money: 0,
        refund_money: 0,
        settle_at: 0
      }
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    getDetail: function getDetail(bill_id) {
      var _this = this;

      fetchTurnoverBillDetail(bill_id).then(function (response) {
        _this.bill = response.data;
        _this.orderLabel = '订单明细(' + response.order_count + ')';
        _this.refundLabel = '退款明细(' + response.refund_count + ')';
      }).catch(function (err) {
        console.log(err);
      });
    },
    getOrderList: function getOrderList() {
      var _this2 = this;

      this.orderData.loading = true;
      var data = this.orderSearchForm;
      fetchTurnoverBillOrderList(data).then(function (response) {
        _this2.orderData.list = response.data;
        _this2.orderData.totalPage = response.meta.total;
      }).catch(function (err) {
        console.log(err);
      });
      this.orderData.loading = false;
    },
    getRefundList: function getRefundList() {
      var _this3 = this;

      this.refundData.loading = true;
      var data = this.refundSearchForm;
      fetchTurnoverBillRefundList(data).then(function (response) {
        _this3.refundData.list = response.data;
        _this3.refundData.totalPage = response.meta.total;
      }).catch(function (err) {
        console.log(err);
      });
      this.refundData.loading = false;
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
          rowIndex = _ref.rowIndex;

      if (parseFloat(row.refund_price) > 0) {
        return 'warning-row';
      }

      return '';
    },
    handleClick: function handleClick(tab, event) {
      if (this.tabName == 'order') {} else {
        if (this.$route.query && this.$route.query.id) {
          this.refundSearchForm['id'] = this.$route.query.id;
          this.getRefundList();
        }
      }
    },
    init: function init() {
      this.bill_id = this.$route.query.id;
      this.getDetail(this.bill_id); // 获取明细

      if (this.$route.query && this.$route.query.id) {
        this.orderSearchForm['id'] = this.bill_id;
        this.getOrderList();
      }
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    }
  }
};